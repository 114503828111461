/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';

function useReportInaccurate() {
  interface RequestData {
    message: string;
    ASIN: string;
  }

  const mutationFn = async ({ ASIN, message }: RequestData): Promise<void> => {
    await axios.post('/api/report-inaccurate', {
      ASIN,
      message
    });
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error reporting deal');
    }
  });
}

export default useReportInaccurate;
