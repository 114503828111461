export const fixCategory = (category: string) => {
  switch (category) {
    case 'Beauty':
    case 'Beauty & Grooming':
    case 'Beauty & Personal Care':
    case 'Luxury Beauty':
      return 'Beauty';
    case 'Health & Household':
    case 'Health & Personal Care':
      return 'Health & Household';
    case 'Automotive Parts and Accessories':
      return 'Automotive';
    case 'Grocery & Gourmet Food':
      return 'Grocery';
    case 'Baby':
    case 'Baby Products':
      return 'Baby';
    default:
      return category;
  }
};

export const allCategories = [
  'Amazon Devices',
  'Amazon Pantry',
  'Appliances',
  'Arts, Crafts & Sewing',
  'Automotive',
  'Baby',
  'Baby Products',
  'Beauty',
  'Beauty & Grooming',
  'Beauty & Personal Care',
  'Books',
  'Camera',
  'CDs & Vinyl',
  'Cell Phones & Accessories',
  'Clothing, Shoes & Jewelry',
  'Electronics',
  'Everything Else',
  'Grocery & Gourmet Food',
  'Gift Cards',
  'Health & Household',
  'Health & Personal Care',
  'Home & Kitchen',
  'Industrial & Scientific',
  'Movies & TV',
  'Office Products',
  'Other',
  'Outdoor Recreation',
  'Patio, Lawn & Garden',
  'Pet Supplies',
  'Special Offers',
  'Sports & Outdoors',
  'Tools & Home Improvement',
  'Toys & Games',
  'Video Games'
];
